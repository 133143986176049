<template>

    <form v-if="form && inputData" class="space-y-6 w-full">

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full">

            <div class="col-span-1">
                <Input name="name" placeholder="Preces nosaukums" v-model="form.name" :errors="errors.name"/>
            </div>

            <div class="col-span-1">
                <Input name="sku" placeholder="Preces kods (SKU)" v-model="form.sku" :errors="errors.sku"/>
            </div>

            <div class="col-span-1">
                <Select v-model="form.measure_unit" :items="inputData.measure_units" :errors="errors.measure_unit"
                        placeholder="Mērvienība"/>
            </div>

            <div class="col-span-1">
                <Autocomplete placeholder="Kategorija" v-model="form.category" :errors="errors.category"
                              :items="inputData.categories" @change="setItemName" :showItems="true"/>
            </div>

            <div class="col-span-1">
                <Input name="base_price" placeholder="Pārdošanas bāzes cena / EUR" v-model="form.base_price"
                       :errors="errors.base_price"/>
            </div>

            <div class="col-span-1">
                <Input name="item_value" placeholder="Pašizmaksa / EUR" v-model="form.item_value"
                       :errors="errors.item_value"/>
            </div>

            <div class="col-span-1">
                <Input name="item_value" placeholder="Noklusējuma reģionālais kods" v-model="form.default_regional_code"
                       :errors="errors.default_regional_code"/>
            </div>

            <div class="col-span-1 flex items-center">
                <Checkbox text="Pakalpojums" v-model="form.is_services"/>
            </div>

        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
            <template v-if="!loading">
                <button type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit">
                    Saglabāt
                </button>
            </template>
            <template v-else>
                <Loading/>
            </template>

            <button @click.prevent="hideEditItemForm" type="button"
                    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
                Atcelt
            </button>

        </div>

    </form>

</template>

<script>
import {mapGetters} from "vuex";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Checkbox from "@/components/Components/Checkbox";
import Autocomplete from "@/components/Components/Autocomplete"

export default {
    name: "EditCatalogItemDetails",
    components: {
        Input,
        Select,
        Loading,
        Checkbox,
        Autocomplete,
    },
    props: ['item'],
    data: () => ({
        form: null,
    }),
    created() {
        this.$store.dispatch("getCatalogItemInputData");
        this.$store.dispatch("clearCatalogItemErrorMessages");
        this.form = this.item
        this.form.is_services = Boolean(this.form.is_services)
    },
    computed: {
        ...mapGetters({
            inputData: "catalogItemInputData",
            loading: "loading",
            errors: 'errors',
        }),
        formValidation() {
            return {
                name: {
                    rules: ['required']
                },
                sku: {
                    rules: ['required']
                },
                measure_unit: {
                    rules: ['required']
                },
                category: {
                    rules: ['required']
                },
            }
        },
    },
    methods: {

        hideEditItemForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
            this.$store.dispatch(
                "getSingleCatalogItem",
                this.$route.params.catalogItemId
            );
        },
        submit() {
            this.$Progress.start()
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateCatalogItem", {
                    id: this.form.id,
                    sku: this.form.sku,
                    name: this.form.name,
                    category_id: this.form.category.id,
                    measure_unit_id: this.form.measure_unit.id,
                    base_price: this.form.base_price,
                    item_value: this.form.item_value,
                    is_services: this.form.is_services,
                    default_regional_code: this.form.default_regional_code,
                });
            } else this.$Progress.fail()
        }
    },
}
</script>